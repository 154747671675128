import React, { useState, useEffect } from 'react';
import SkeletonArticle from '../skeletons/SkeletonArticle';

const Articles = ({ appTheme }) => {
	const [ articles, setArticles ] = useState(null);
	const appThemeClass = appTheme || 'light';

	useEffect(() => {
		setTimeout(async () => {
			const res = await fetch('https://jsonplaceholder.typicode.com/posts');
			const data = await res.json();
			let postList = data.slice(0, 10);
			// console.log('Data ', data);
			setArticles(postList);
		}, 5000);
	}, []);

	useEffect(
		() => {
			// console.log('articles ', articles);
		},
		[ articles ]
	);

	return (
		<div className="articles">
			<h2>Articles</h2>

			{articles &&
				articles.map((article) => (
					<div className="article" key={article.id}>
						<h3> {article.title} </h3>
						<p> {article.body} </p>
						<p> Post Number: {article.id} </p>
					</div>
				))}
			{!articles &&
				[ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ].map((n) => <SkeletonArticle key={n} theme={appThemeClass} />)}
		</div>
	);
};

export default Articles;
