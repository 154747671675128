import React, { useEffect, useState } from 'react';
import SkeletonProfile from '../skeletons/SkeletonProfile';

const User = ({ appTheme }) => {
	const [ profile, setProfile ] = useState(null);
	const appThemeClass = appTheme || 'light';

	useEffect(() => {
		let userId = Math.floor(Math.random() * 10) + 1;
		setTimeout(async () => {
			const res = await fetch(`https://jsonplaceholder.typicode.com/users/${userId}`);
			const data = await res.json();
			// console.log('Data ', data);
			setProfile(data);
		}, 5000);
	}, []);

	return (
		<div className="user">
			<h2>User Details</h2>
			{profile && (
				<div className="profile">
					<h3>{profile.username}</h3>
					<p>{profile.email}</p>
					<a href={profile.website}> {profile.website} </a>
				</div>
			)}

			{!profile && <SkeletonProfile theme={appThemeClass} />}
		</div>
	);
};

export default User;
