import React, { useEffect, useState } from 'react';
import Articles from './components/Articles';
import User from './components/User';

function App() {
	const [ theme, setTheme ] = useState('light');

	const handleTheme = () => {
		let changeTheme = theme === 'light' ? 'dark' : 'light';
		localStorage.setItem('appTheme', changeTheme);
		setTheme(changeTheme);
	};

	useEffect(() => {
		let checkTheme = localStorage.getItem('appTheme');
		let localTheme = checkTheme === 'light' || checkTheme === 'dark' ? checkTheme : 'light';
		if (!checkTheme) {
			localStorage.setItem('appTheme', 'light');
		} else {
			setTheme(localTheme);
		}
	}, []);

	// useEffect(
	// 	() => {
	// 		console.log('Change Theme APP theme ', theme);
	// 	},
	// 	[ theme ]
	// );

	return (
		<div className="App">
			<header>
				<h1> React Skeletons</h1>
				<button
					className={theme === 'light' ? 'theme-button-light' : 'theme-button-dark'}
					onClick={handleTheme}
				>
					{theme === 'light' ? 'Dark Theme' : 'Light Theme'}
				</button>
			</header>
			<div className="content">
				<Articles appTheme={theme} />
				<User appTheme={theme} />
			</div>
		</div>
	);
}

export default App;
