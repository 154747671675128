import React from 'react';
import './skeleton.css';

const Shimmer = () => {
	return (
		<div className="shimmer-wrapper">
			<div className="shimmer" />
		</div>
	);
};

export default Shimmer;
